/* Police du titre du Juke Box */
@font-face {
   font-family: "PoliceEnTete";
   src: local("RetroBeach"), url("./font/RetroBeach.otf") format("truetype");
}

/* Police utilisée pour la légende dans l'en-tête du Juke Box*/
@font-face {
   font-family: "PoliceLegende";
   src: local("Mont-Heavy"), url("./font/Mont-Heavy.otf") format("truetype");
}

/* Police des symboles ajoutés avant et après l'auteur d'un morceau */
@font-face {
   font-family: "PoliceSymboles";
   src: local("Cornucopia of Ornaments Four"), url("./font/CornucopiaOfOrnamentsFour.ttf") format("truetype");
}

/* Police utilisée pour afficher l'auteur d'un morceau */
@font-face {
   font-family: "PoliceAuteur";
   src: local("Heroes Legend Hollow"), url("./font/HeroesLegendHollow.ttf") format("truetype");
}

/* Police utilisée pour afficher le titre d'un morceau */
@font-face {
   font-family: "PoliceTitre";
   src: local("Yokelvision"), url("./font/Yokelvision.otf") format("truetype");
}

/* classe permettant d'afficher les titres, auteurs et symboles dans les cartouches des morceaux */
.titres {
   font-family: "PoliceTitre" !important;
   font-size: 1.5em !important;
}
.auteurs {
   font-family: "PoliceAuteur" !important;
   font-size: 0.7em !important;
}
.symboles {
   font-family: "PoliceSymboles" !important;
   font-size: 1.5em !important;
   color: #E88284 !important
}

/* définition des classes de défilement automatique des libellés. Les keyframes sont définis dynamiquement dans le code React */
/* Source: https://blog.viaxoft.com/texte-defilant-en-css */
 .defileurParentTitre {
	display: block;
	overflow: hidden;
	position: relative;
	table-layout: fixed;
	width: calc(100% - 29px);
   left: 17px
}

.defileurParentAuteur {
  display: block;
  overflow: hidden;
  position: relative;
  table-layout: fixed;
  width: calc(100% - 119px);
  left: 60px
}

.defileur {
   display: block;
	text-align:center;
   text-overflow: clip;
   white-space: nowrap;
}

/* Tâches et fond des cartouches */
/* usage : <section class="stain"><div class="main-stain"></div><div class="splash-stain"></div></section> */
/*
body {
   margin: 0;
   min-height: 100vh;
   overflow: hidden;
   background: radial-gradient(circle at calc(50% + 12vmin) calc(50% - 4vmin), #fff 29vmin, #eee)
 }
 */
 
 section {
   width: 40vmin;
   aspect-ratio: 1;
   position: absolute;
 }
 
 section *,
 section *::before,
 section *::after {
   position: absolute;
   box-sizing: border-box;
 }
 
 .stain {
   opacity: 0.35;
   left: 40%;
   top: -5%;
   filter: sepia(200%) brightness(360%) contrast(130%) hue-rotate(-15deg) grayscale(20%);
   background: #eee;
   mix-blend-mode: darken;
   -webkit-mask:
     radial-gradient(farthest-side at 25% 40%, #000 20%, #0000 45%),
     radial-gradient(#0004, #0000) 10% 25% / 17% 21%,
     radial-gradient(#0006, #0000) 30% 30% / 20% 20%,
     radial-gradient(#000c, #0000),
     radial-gradient(farthest-side, #0000 80%, #000)
 }
 
 .main-stain {
   top: 50%;
   left: 55%;
   transform: translate(-50%, -50%);
   width: 80%;
   aspect-ratio: 1;
   border-radius: 50%;
   background: 
     radial-gradient(circle at 70% 20%, #00f 10%, #0000 0),
     radial-gradient(circle at 81% 40%, #00f 6%, #0000 0),
     radial-gradient(circle at 20% 20%, #00f 6%, #0000 0),
     #fff
     ;
   box-shadow: 
     inset 5vmin -0.75vmin 0 -2.75vmin #006,
     inset -1vmin -1vmin blue,
     inset -1.5vmin 2.5vmin blue,
     1vmin 2vmin blue,
     -1vmin 2vmin 0 -1vmin blue,
     0 0 0 16vmin #fff;
   filter: blur(1.5vmin) contrast(500);
 }
 
 .splash-stain {
   width: 12vmin;
   aspect-ratio: 1;
   background:
     repeating-conic-gradient(#00f 0 3%, #0000 0 11%),
     repeating-conic-gradient(#0000 0 5%, #00f 0 7%) 50% / 60% 60%,
     repeating-conic-gradient(#0000 0 7%, #00f 0 9%) 50% / 70% 70%,
     repeating-conic-gradient(#0000 0 11%, #00f 0 13%) 50% / 80% 80%,
     radial-gradient(#00f 22%, #0000 0),
     #fff;
   background-repeat: no-repeat;
   filter: blur(0.78vmin) contrast(50) drop-shadow(0 0 0.75vmin #f008);
   box-shadow: 0 0 0 6vmin #fff;
   mix-blend-mode: darken;
   top: -1%;
   left:63%;
   border-radius: 50%
 }
 